import React from 'react'
import styled from 'styled-components'
import LoginBanner from '../../components/common/LoginBanner'
import Success from './Success'

const Text = {
  Subtitle: styled.p`
    margin-top: 15px;
    /* Headers/H6 */

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* White/100 */

    color: #ffffff;
  `,
  DontHaveAnAccount: styled.p`
    /* Body/Small/Semibold */

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143%;
    /* identical to box height, or 20px */

    text-align: center;
    letter-spacing: 0.018em;

    /* White/100 */

    color: #ffffff;
  `
}

const OuterContainer = styled.div`
  @media only screen and (max-width: 768px) {
    min-height: 500px;
  }
  @media only screen and (min-width: 767px) {
    height: 500px;
  }
`

// markup
const Page = (props) => {
  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center no-gutters">
          <div className="col-lg-8 col-xxl-5 py-3">
            <OuterContainer className="card overflow-hidden z-index-1">
              <div className="card-body p-0">
                <div className="row no-gutters h-100">
                  <LoginBanner />
                  <div className="col-md-7 d-flex flex-center">
                  <Success title="Your token is invalid" content="Please contact your Admin use to re-send the invite."/>
                  </div>
                </div>
              </div>
            </OuterContainer>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Page
