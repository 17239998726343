import React from 'react'
import styled from 'styled-components'

const HeaderGroup = styled.div`
font-family: Inter;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 150%;
color: '#111';
`
const Success = (props) => {
  return (
    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5">
      {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
      <div className="d-flex justify-content-between">
        <HeaderGroup className="mb-3">{props.title}</HeaderGroup>
      </div>
      <p>
        {props.content}
      </p>
    </div>
  )
}

export default Success
